import React from "react";
import Layout from "../../components/Layout";

const Impressum = () => {
	return (
		<Layout>
			<div className="bg-primaryLight flex-grow py-20 md:py-[100px]">
				<div className="container">
					<div className="max-w-[948px] mx-auto grid grid-cols-1 md:grid-cols-2 gap-[30px] md:text-lg">
						<div>
							<h5 className="h5">Impressum</h5>
							<div className="mb-6">
								Angaben gemäß § 5 TMG: <br />
								Linn Tunger <br /> An Hang 8 <br /> 23909 Bäk
							</div>
							<div className="mb-6">
								Kontakt: <br />
								Telefon: 04541 6061459 <br />
								E-Mail: info@reittraining-buchen.de
							</div>
							<br />
							<div>
								Verantwortlich für den Inhalt nach § 55 Abs. 2 RStV:{" "}
								<br />
								Linn Tunger <br />
								An Hang 8 <br />
								23909 Bäk
							</div>
						</div>
						<div>
							<h5 className="h5">Haftungsausschluss: </h5>
							<p className="mb-6">
								Die Informationen auf dieser Website werden mit größter
								Sorgfalt erstellt. Ich übernehme jedoch keine Gewähr für
								die Richtigkeit, Vollständigkeit und Aktualität der
								bereitgestellten Inhalte.
							</p>
							<p className="mb-6">
								Links zu externen Websites: <br /> Diese Website enthält
								Links zu externen Websites. Ich habe keinen Einfluss auf
								die Inhalte dieser Seiten und übernehme dafür keine
								Haftung.
							</p>
							<p className="">
								Datenschutz: <br /> Für Informationen zur Erhebung und
								Verarbeitung personenbezogener Daten verweisen wir auf
								unsere Datenschutzerklärung.
							</p>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Impressum;
