import React from "react";

const DigitalRidingAdvantages = () => {
	return (
		<section
			className="bg-primaryLight pb-8 pt-10 md:pt-[56px] md:pb-[42px]"
			id="Vorteile"
		>
			<div className="container">
				<div className="max-w-[1012px] mx-auto">
					<div className="max-w-[768px]">
						<h2 className="h2 mb-5">
							Vorteile des digitalen Reittrainings.
						</h2>
						<p className="md:text-lg mb-10">
							Keine Zeit für lange Anfahrten oder fehlende Trainer vor
							Ort? Mit meinem Online-Reitunterricht trainierst du
							flexibel, unabhängig und direkt in deiner vertrauten
							Umgebung über einen Videocall.
						</p>
					</div>
					<div className="grid grid-cols-1 md:grid-cols-2 gap-y-[30px] gap-x-[30px]">
						{data.map((item, index) => (
							<div
								className="flex items-start gap-5 sm:gap-[30px]"
								key={index}
							>
								{item.icon}
								<div className="w-0 flex-grow">
									<h5 className="text-lg font-semibold sm:text-xl mb-1 sm:leading-[1.5]">
										{item.title}
									</h5>
									<p className="sm:text-lg xl:max-w-[381px]">
										{item.text}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</section>
	);
};

const advantagesIcons = {
	home: (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="24" fill="#85E7D6" />
			<path
				d="M21 34.5V24.5H27V34.5M15 21.5L24 14.5L33 21.5V32.5C33 33.0304 32.7893 33.5391 32.4142 33.9142C32.0391 34.2893 31.5304 34.5 31 34.5H17C16.4696 34.5 15.9609 34.2893 15.5858 33.9142C15.2107 33.5391 15 33.0304 15 32.5V21.5Z"
				stroke="#001F2B"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	),
	directBuchen: (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="24" fill="#85E7D6" />
			<path
				d="M21 23.5L24 26.5L34 16.5M33 24.5V31.5C33 32.0304 32.7893 32.5391 32.4142 32.9142C32.0391 33.2893 31.5304 33.5 31 33.5H17C16.4696 33.5 15.9609 33.2893 15.5858 32.9142C15.2107 32.5391 15 32.0304 15 31.5V17.5C15 16.9696 15.2107 16.4609 15.5858 16.0858C15.9609 15.7107 16.4696 15.5 17 15.5H28"
				stroke="#181D27"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	),
	user: (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="24" fill="#85E7D6" />
			<path
				d="M29 33.5V31.5C29 30.4391 28.5786 29.4217 27.8284 28.6716C27.0783 27.9214 26.0609 27.5 25 27.5H18C16.9391 27.5 15.9217 27.9214 15.1716 28.6716C14.4214 29.4217 14 30.4391 14 31.5V33.5M30 23.5L32 25.5L36 21.5M25.5 19.5C25.5 21.7091 23.7091 23.5 21.5 23.5C19.2909 23.5 17.5 21.7091 17.5 19.5C17.5 17.2909 19.2909 15.5 21.5 15.5C23.7091 15.5 25.5 17.2909 25.5 19.5Z"
				stroke="#001F2B"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	),
	time: (
		<svg
			width="48"
			height="48"
			viewBox="0 0 48 48"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
		>
			<rect width="48" height="48" rx="24" fill="#85E7D6" />
			<path
				d="M24 18.5V24.5L28 26.5M34 24.5C34 30.0228 29.5228 34.5 24 34.5C18.4772 34.5 14 30.0228 14 24.5C14 18.9772 18.4772 14.5 24 14.5C29.5228 14.5 34 18.9772 34 24.5Z"
				stroke="#001F2B"
				stroke-width="2"
				stroke-linecap="round"
				stroke-linejoin="round"
			/>
		</svg>
	),
};

const data = [
	{
		icon: advantagesIcons.home,
		title: "Gewohnte Umgebung",
		text: "Du trainierst in deiner vertrauten Umgebung – ohne Stress für dich und dein Pferd.",
	},
	{
		icon: advantagesIcons.directBuchen,
		title: "Coaching direkt buchen",
		text: "Buche dein Training jederzeit direkt online – auch kurzfristige Sessions sind möglich!",
	},
	{
		icon: advantagesIcons.user,
		title: "Unabhängig vom regionalen Trainerangebot",
		text: "Unabhängig von regionalen Trainingsangebot erhältst du die professionelle Unterstützung, die du und dein Pferd brauchen.",
	},
	{
		icon: advantagesIcons.time,
		title: "Zeitsparend",
		text: "Wähle deine Zeit im Kalender, buche und fertig. Keine aufwendigen Absprachen zur Terminfindung notwendig.",
	},
];
export default DigitalRidingAdvantages;
