import React from "react";
import { HashLink } from "react-router-hash-link";

const TrainingCourse = () => {
	return (
		<section
			className="training-course bg-primaryLight pt-[50px] pb-[60px] md:pb-[70px] max-lg:pt-[90px]"
			style={{
				WebkitMask: `url(/img/training-course.png) no-repeat center top / cover`,
			}}
			id="Ablauf"
		>
			<div className="container">
				<div className="max-w-[1093px] mx-auto">
					<h2 className="h2 mb-5">Ablauf des Trainings</h2>
					<div className="flex flex-col md:flex-row md:text-lg gap-10">
						<div className="md:w-0 flex-grow xl:max-w-[515px]">
							<strong>Deine Vorbereitung</strong> Installiere dir die
							Zoom-App und teste deine Internetverbindung. Logge dich
							rechtzeitig <br className="hidden md:block" /> (spätestens
							10 min vor Start) ein und stelle sicher, dass alles bereit
							ist. Du solltest vor dem Trainingsbeginn bereits dein Pferd
							warm geritten haben.
							<HashLink to="#FAQ" className="block font-bold">
								Mehr Infos zur Vorbereitung findest du hier{" "}
								<svg
									width="14"
									height="14"
									viewBox="0 0 14 14"
									fill="none"
									xmlns="http://www.w3.org/2000/svg"
									className="inline-block"
								>
									<path
										d="M6.87473 13.7159L5.39178 12.2457L9.28454 8.35298H0.163086V6.19247H9.28454L5.39178 2.30611L6.87473 0.829545L13.3179 7.27273L6.87473 13.7159Z"
										fill="#001F2B"
									/>
								</svg>
							</HashLink>
						</div>
						<div className="md:w-0 flex-grow">
							<strong>Start des Trainings</strong> Sobald wir beide in
							unserem Videocall eingeloggt sind, beginnt unsere
							Trainingseinheit. Während des Reitens gebe ich dir Impulse
							um deinen Ziel schrittweise näher zu kommen. Am Ende des
							Trainings erhältst du eine Zusammenfassung, damit du auch
							in deinem selbstständigen Training weiter daran arbeiten
							kannst.
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default TrainingCourse;
