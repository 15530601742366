import React from "react";
import { howItIcons } from "../../../components/icons";

const HowBookingWorks = () => {
	return (
		<section className="how-booking-works py-10 md:pt-[50px] md:pb-[62px]">
			<div className="container">
				<div className="max-w-[1120px] mx-auto">
					<div className="grid grid-cols-1 lg:grid-cols-12 gap-8 place-items-center pb-12">
						<div className="col-span-1 lg:col-span-5">
							<div className="xl:max-w-[441px]">
								<h2 className="h2 mb-[10px]">
									So funktioniert die Buchung.
								</h2>
								<p className="md:text-lg">
									Keine Zeit für lange Anfahrten oder fehlende Trainer
									vor Ort? Mit meinem Online-Reittraining trainierst du
									flexibel, unabhängig und direkt in deiner vertrauten
									Umgebung in einem Videocall.
								</p>
							</div>
						</div>
						<div className="col-span-1 lg:col-span-7 w-full">
							<ul className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:text-center">
								{steps.map((item, index) => (
									<li
										className="p-5 sm:py-10 rounded-2xl bg-primary font-semibold lg:text-xl sm:flex sm:items-center md:min-h-[191px]"
										key={index}
										dangerouslySetInnerHTML={{
											__html: index + 1 + "." + item.title,
										}}
									></li>
								))}
							</ul>
						</div>
					</div>
					<div className="py-[30px] md:py-[15px] px-[30px] rounded-[20px] bg-white gap-5 flex flex-wrap">
						<div className="flex flex-wrap text-cta gap-5 max-md:flex-grow">
							<div className="w-[125px] text-sm font-medium max-sm:flex-grow">
								Das benötigst <br className="hidden md:block" /> für
								einen digitalen Reituntericht!
							</div>
							<div className="w-[61px]">
								<span className="hidden lg:block">{arrow1}</span>
								<span className="lg:hidden">{arrow2}</span>
							</div>
						</div>
						<div className="w-full lg:w-0 flex-grow flex flex-col md:flex-row gap-5 max-lg:flex-wrap">
							{data.map((item, index) => (
								<div
									className="flex items-center gap-5 max-lg:w-1/3 max-lg:flex-grow max-md:w-full"
									key={index}
								>
									<div className="min-w-12">{item.icon}</div>
									<div
										dangerouslySetInnerHTML={{
											__html: item.title,
										}}
									></div>
								</div>
							))}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const steps = [
	{
		title: "Termin <br class='hidden md:inline-block' /> im Kalender auswählen.",
	},
	{
		title: "Infos über dich und dein Pferd angeben",
	},
	{
		title: "Buchen & Zahlung vornehmen",
	},
];
const data = [
	{
		icon: howItIcons.mobile,
		title: "Internetfähiges <br class='md:hidden' /> Endgerät mit Kamera",
	},
	{
		icon: howItIcons.bluetooth,
		title: "Bluetooth-Kopfhörer",
	},
	{
		icon: howItIcons.cog,
		title: "Zoom-App installiert",
	},
	{
		icon: howItIcons.rocket,
		title: "ausreichend schnelles Internet",
	},
];
const arrow1 = (
	<svg
		width="61"
		height="57"
		viewBox="0 0 61 57"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M13.457 25.6869C24.0486 17.149 38.3464 15.4721 51.4959 16.1227C52.1717 16.1561 52.8649 16.1484 53.5346 16.1547C52.565 15.1377 44.1961 7.56054 45.3848 6.16382C46.0795 5.34754 47.1447 6.3468 47.578 6.73981C50.7101 9.58285 53.474 12.2526 56.3782 15.3445C58.5646 17.6718 56.9503 19.9343 55.2277 22.1305C52.5978 25.4834 52.1285 25.6624 49.2771 29.0391C48.8071 29.5956 48.3704 30.2012 47.8585 30.6893C47.2563 31.263 46.2478 31.746 45.5817 31.0826C44.8267 30.3308 45.2244 29.4377 45.8428 28.7762C45.9723 28.6382 46.0969 28.4956 46.2181 28.35C47.5483 26.7505 54.1115 19.6823 53.617 19.1873C53.4506 19.0204 53.2056 18.9672 52.9742 18.9232C46.2982 17.6509 39.3078 18.1723 32.657 19.3733C28.1817 20.1811 23.8415 21.7288 19.8571 23.923C16.6553 25.6861 13.6748 27.9028 11.1942 30.5965C8.53394 33.4848 7.04166 36.6739 5.50143 40.2333C4.61496 42.2824 3.22337 39.8974 3.42275 38.6503C3.64149 37.2796 4.58479 36.0702 5.29662 34.9203C7.55055 31.2834 10.3155 28.2194 13.457 25.6869Z"
			fill="#01C1A0"
		/>
	</svg>
);
const arrow2 = (
	<svg
		width="62"
		height="55"
		viewBox="0 0 62 55"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M28.8646 9.84713C39.8529 17.8681 45.1732 31.2447 47.9481 44.1145C48.0908 44.7759 48.2776 45.4436 48.4448 46.092C49.1762 44.8923 54.3292 34.8474 55.9859 35.6341C56.9542 36.0939 56.2647 37.3814 55.9972 37.9017C54.0617 41.6629 52.1983 45.0235 49.9634 48.629C48.2813 51.3432 45.6781 50.3696 43.1109 49.2741C39.1915 47.6016 38.8972 47.1946 34.8975 45.3144C34.2383 45.0043 33.5403 44.7393 32.9364 44.3712C32.2264 43.9379 31.4988 43.0888 31.9672 42.2737C32.498 41.3499 33.4636 41.5029 34.2626 41.929C34.4294 42.0184 34.5994 42.1018 34.7714 42.1812C36.6607 43.0521 45.1867 47.5622 45.5369 46.9565C45.655 46.7525 45.643 46.5022 45.6257 46.2673C45.1267 39.4894 42.8138 32.8721 39.9324 26.7588C37.9938 22.6451 35.3755 18.8534 32.2249 15.5727C29.6932 12.9362 26.7805 10.6311 23.5367 8.93215C20.0582 7.11008 16.5915 6.49406 12.7548 5.92755C10.546 5.60163 12.4897 3.64017 13.7458 3.50998C15.1265 3.3665 16.5388 3.96465 17.8337 4.35461C21.9301 5.59044 25.6053 7.46814 28.8646 9.84713Z"
			fill="#01C1A0"
		/>
	</svg>
);

export default HowBookingWorks;
