import React, { useEffect } from "react";

const ChooseDate = () => {
	useEffect(() => {
		const script = document.createElement("script");
		script.src = "https://assets.calendly.com/assets/external/widget.js";
		script.async = true;
		document.body.appendChild(script);

		return () => {
			// Cleanup: remove the script to prevent conflicts
			document.body.removeChild(script);
		};
	}, []);
	return (
		<section className="choose-date-section">
			<div className="container">
				<div className="md:text-center md:mx-auto md:max-w-[830px] pt-7">
					<h3 className="text-2xl mb-[10px] font-semibold">
						Was ist ein digitales LIVE-Reittraining?
					</h3>
					<p className="md:text-lg">
						Mit der Live-Videoübertragung begleite ich dich und dein Pferd
						während des Reitens in Echtzeit. So kann ich euch direkt
						unterstützen und individuell anleiten,{" "}
						<strong>als wäre ich vor Ort</strong>.
					</p>
				</div>
				<div id="Booking"></div>
				<div className="mt-[66px] text-center pb-[42px] md:pb-[56px]">
					<h2 className="h2">Wähle jetzt deinen Termin aus!</h2>
					<div
						className="calendly-inline-widget"
						data-url="https://calendly.com/reittraining-buchen-info/30min"
						style={{ minWidth: "320px", height: "800px" }}
					></div>
				</div>
			</div>
		</section>
	);
};

export default ChooseDate;
