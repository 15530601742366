import React from "react";
import "swiper/css/bundle";
import { Swiper, SwiperSlide } from "swiper/react";

const Reviews = () => {
	return (
		<section className="reviews-section bg-sand py-[60px] overflow-hidden">
			<div className="container">
				<div className="max-w-[1340px] mx-auto">
					<Swiper
						slidesPerView={"auto"}
						spaceBetween={20}
						className="overflow-visible"
					>
						{data.map((item, index) => (
							<SwiperSlide key={index} className="w-[320px] h-auto">
								<div className="py-10 px-[30px] bg-white rounded-[20px] h-full">
									<div className="flex flex-wrap gap-[13px] items-center">
										<div className="w-[52px] h-[52px] bg-[#D9D9D9] rounded-full flex justify-center items-center font-medium text-xl text-white">
											{getShortName(item.name)}
										</div>
										<div className="w-0 flex-grow font-medium tracking-tighter leading-6">
											<div className="mb-[-2px]">{item.name}</div>
											<div>{item.created_at}</div>
										</div>
									</div>
									<div className="flex mt-2 mb-4">
										{star}
										{star}
										{star}
										{star}
										{star}
									</div>
									<p className="font-medium tracking-[-0.025em] leading-[22px]">
										{item.quote}
									</p>
								</div>
							</SwiperSlide>
						))}
					</Swiper>
				</div>
			</div>
		</section>
	);
};
function getShortName(fullName) {
	// Split the full name into an array of words
	const nameParts = fullName.split(" ");

	// Take the first letter of each part (word)
	const shortName = nameParts
		.map((part) => part.charAt(0).toUpperCase())
		.join("");

	// Return the short name (up to 2 characters)
	return shortName.slice(0, 2);
}
const data = [
	{
		name: "Vanessa S.",
		created_at: "19.10.2024",
		quote: "Toller Reitunterricht. Linn punktet durch ihre ruhige/freundliche Art und ihrer Erfahrung mit vielen Erklärungen/Veranschaulichungen. Was mir aber am allerbesten gefällt ist, dass sie immer aufs Pferdewohl bedacht ist und ganzheitlich schaut.",
	},
	{
		name: "Denise K.",
		created_at: "28.10.2024",
		quote: "Die Terminfindung war unkompliziert und flexibel. Der Unterricht selbst war äußerst attraktiv und es gibt praktisch keine Nachteile im Vergleich zum Präsenzunterricht. Insgesamt bin ich sehr zufrieden und kann den Online-Unterricht uneingeschränkt empfehlen!",
	},
	{
		name: "Angelina",
		created_at: "16.11.2024",
		quote: "Ich habe Linn als eine sehr sympathische Person kennen gelernt. Linn holt einen genau dort ab, wo man steht. Sie ist immer darauf bedacht, dass es dem Pferd und Menschen gut geht und sie sich wohl fühlen. Ich habe schon ab der ersten Unterrichtseinheit viel wissen mitnehmen dürfen. Remmi und ich freuen uns sehr auf unsere nächste Trainingsstunde.",
	},
	{
		name: "Johanna B.",
		created_at: "27.11.2024",
		quote: "Positiv beim Online Reittraining war, dass man gar nicht gemerkt hat, dass Linn nicht wirklich dabei gewesen ist. Man hat alles gut verstanden und ich fand es super, dass du auch erklärt hast, warum ich was machen soll.",
	},
];
const star = (
	<svg
		width="24"
		height="24"
		viewBox="0 0 24 24"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M12 2L15.09 8.26L22 9.27L17 14.14L18.18 21.02L12 17.77L5.82 21.02L7 14.14L2 9.27L8.91 8.26L12 2Z"
			fill="#FFD500"
		/>
	</svg>
);

export default Reviews;
