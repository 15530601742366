import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
	return (
		<footer>
			<div className="container">
				<div className="flex flex-wrap justify-center gap-x-9 md:justify-between mx-auto py-5 text-xs md:text-lg max-w-[1090px]">
					<div>&copy;2025</div>
					<ul className="flex flex-wrap gap-4 gap-x-9">
						<li>
							<Link
								to="/datenschutz"
								className="hover:text-primary duration-300 transition-all"
							>
								Datenschutz & AGB
							</Link>
						</li>
						<li>
							<Link
								to="/impressum"
								className="hover:text-primary duration-300 transition-all"
							>
								Impressum
							</Link>
						</li>
					</ul>
				</div>
			</div>
		</footer>
	);
};

export default Footer;
