import React from "react";
import Layout from "../../components/Layout";

const Datenschutz = () => {
	return (
		<Layout>
			<div className="bg-primaryLight flex-grow py-20 md:py-[100px]">
				<div className="container">
					<div className="max-w-[948px] mx-auto grid grid-cols-1 md:grid-cols-12 gap-[30px] md:text-lg">
						<div className="md:col-span-8">
							<h5 className="h5">
								Allgemeine Geschäftsbedingungen (AGB)
							</h5>
							<p className="mb-6">
								Mit der Buchung einer Unterrichtsstunde, einer
								Videoanalyse oder eines Beratungsgesprächs werden die
								Allgemeinen Geschäftsbedingungen (AGB) akzeptiert.
								Nachfolgend findest du die vollständigen Bestimmungen,
								die für alle unsere Dienstleistungen gelten:
							</p>
							<br />
							<ul className="flex flex-col gap-5">
								<li>1. Geltung der Allgemeinen Geschäftsbedingungen</li>
								<li>
									1.1. Gegenständliche Allgemeine Geschäftsbedingungen
									(im Folgenden AGB) gelten für sämtliche zwischen Linn
									Tunger (in weiterer Folge als „Auftragnehmer“) mit
									einem Verbraucher (in weiterer Folge als
									„Auftraggeber“ bezeichnet) abgeschlossene
									Rechtsgeschäfte über die Erteilung von mobilen und
									digitalen Reitunterricht und Beritt.
								</li>
								<li>
									Die nachstehenden AGB gelten in der am Tag der
									Auftragserteilung gültigen Fassung für das
									gegenständliche Rechtsgeschäft. Mit Auftragserteilung
									erklärt sich der Auftraggeber mit diesen AGB
									einverstanden und an sie gebunden. Die aktuell
									gültige Fassung der AGB ist auf der Homepage
									www.reittraining-buchen.de abrufbar und wurden diese
									auch an den Auftraggeber ausgehändigt und/oder
									übermittelt.
								</li>
								<li>
									1.2. Verbraucher im Sinne dieser AGB ist jene Person,
									für welche das mit Linn Tunger geschlossene
									Rechtsgeschäft nicht zum Betrieb eines Unternehmens
									im Sinne des § 1. Abs. 2 KSchG gehört.
								</li>
								<li>
									1.3. Sonstige Geschäftsbedingungen des Auftraggebers
									haben keine Gültigkeit und wird diesen hiermit
									ausdrücklich widersprochen.
								</li>
								<li>
									1.4. Der Auftraggeber erklärt, dass er mit dem Inhalt
									der AGB einverstanden ist und vor Vertragsabschluss
									die Möglichkeit hatte, von diesen Kenntnis zu nehmen.
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>2. Vertragsabschluss</li>
								<li>
									Angebote der Auftragnehmerin sind freibleibend und
									unverbindlich. Diese behält sich das Recht vor, das
									jeweilige Leistungsangebot inhaltlich jederzeit zu
									verändern. Mit der Anmeldung zu einem der Angebote
									gibt der Auftraggeber ein für ihn bindendes Angebot
									auf Abschluss eines Vertrages mit der Auftragnehmerin
									ab. Der Vertrag kommt durch Annahme der Beauftragung
									durch die Auftragnehmerin zustande, und zwar entweder
									durch schriftliche Auftrags- und Terminbestätigung
									oder durch Beginn der beauftragten Arbeiten.
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>3. Preise und Fälligkeit</li>
								<li>
									3.1. Die angebotenen Preise gelten, soweit nichts
									anderes vereinbart ist, als Festpreise ggf. inkl.
									Anfahrt.
								</li>
								<li>
									3.2. Das Entgelt für Reitstunden ist unmittelbar nach
									der mobilen Reitstunde in bar bzw. binnen 5 Tagen ab
									Erhalt der Rechnung auf das Konto der Auftragnehmerin
									zur Anweisung zu bringen. Bei digitalem
									Reitunterricht ist das Entgelt gemäß der zur
									Verfügung stehenden Bezahlvarianten zu erbringen.
								</li>
								<li>
									3.3. Im Falle des nicht fristgerechten
									Zahlungseinganges ist die Auftragnehmerin berechtigt,
									vom Vertrag zurückzutreten und den Auftraggeber vom
									Unterricht auszuschließen.
								</li>
								<li>
									3.4. Im Falle des Zahlungsverzuges werden
									Verzugszinsen in der Höhe von 4 % vereinbart. Durch
									Zahlungsverzug entstandene, zweckmäßige und
									notwendige Kosten, beispielsweise für Inkasso,
									Mahnungen etc., sowie allfällige gerichtliche oder
									außergerichtliche Betreibungskosten, einschließlich
									jener der rechtsfreundlichen Vertretung, sind der
									Auftragnehmerin vom Auftraggeber zu ersetzen.
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>4. Verschiebung, Stornierung und Rücktritt</li>
								<li>
									4.1. Der Auftraggeber ist im Fall des mobilen und
									digitalten Unterrichts berechtigt, bis zu 24 Stunden
									vor dem vereinbarten Termin diesen kostenlos
									abzusagen. Im Falle der Stornierung von weniger als
									24 Stunden vor dem vereinbarten Termin ist die
									Auftragnehmerin berechtigt, die Einheit zur Gänze zu
									verrechnen oder es besteht die Möglichkeit, den auf
									digitalem Wege einmalig zu verschieben und innerhalb
									von 5 Tagen nachzuholen.
								</li>
								<li>
									Wurden seitens des Auftraggebers regelmäßige Termine
									(1 x wöchentlich oder 14-tägig) im Vorhinein gebucht
									und die vereinbarten Termine in weiterer Folge 3 x
									hintereinander abgesagt, ist die Auftragnehmerin
									berechtigt, diesen Termin nicht weiter für den
									Auftraggeber freizuhalten und den Termin anderen
									Kunden zur Verfügung zu stellen.
								</li>
								<li>
									4.2. Die Auftragnehmerin behält sich vor, aus
									Krankheits- oder Organisationsgründen Einheiten zur
									Gänze abzusagen oder zu verschieben. Diesfalls wird
									die Auftragnehmerin einen Ersatztermin mit dem
									Auftraggeber vereinbaren.
								</li>
								<li>
									4.3. Ein Paket (Bronze / Silber / Gold) kann nur dann
									pausiert werden, wenn ein Nachweis des Tierarztes
									bzw. ein gesundheitlicher Nachweis des Reiters der
									den Ausfall entschuldigt spätestens 24 h vor
									Terminbeginn vorgelegt wird. Der Zeitraum des
									Pausierens ist auf 6 aufeinander folgende Monate
									begrenzt.
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>5. Pflichten des Auftraggebers</li>
								<li>
									5.1. Der Auftraggeber ist verpflichtet, während des
									Reitens einen Reithelm, welcher der aktuellen
									DIN-Norm entspricht, zu tragen. Darüberhinausgehend
									ist der Auftraggeber verpflichtet, während des
									Umgangs mit den Pferden festes Schuhwerk und
									gegebenenfalls Handschuhe zu tragen.
								</li>
								<li>
									Der Abschluss einer Unfallversicherung wird
									ausdrücklich empfohlen.
								</li>
								<li>
									5.2. Der Auftraggeber ist verpflichtet, sich
									pünktlich zur vereinbarten Zeit am Veranstaltungsort
									einzufinden. Der Auftraggeber nimmt zur Kenntnis,
									dass allfällige Verspätungen seinerseits nicht
									berücksichtigt werden können.
								</li>
								<li>
									5.3. Der Auftraggeber verpflichtet sich weiter,
									eventuelle Missstände, Krankheiten und
									Verhaltensauffälligkeiten des Pferdes anzuzeigen, um
									sich und die Ausbildnerin nicht in unnötige Gefahr zu
									bringen.
								</li>
								<li>
									5.4. Technische Voraussetzungen und Verantwortung  
								</li>
								<li>
									5.4.1. Der Auftraggeber ist dafür verantwortlich,
									dass während des digitalen Unterrichts eine stabile
									Internetverbindung sowie geeignete technische Geräte
									(z. B. Smartphone, Laptop, Bluetooth-Kopfhörer,
									Kamera, Stativ) vorhanden sind.  
								</li>
								<li>
									5.4.2. Technische Störungen auf Seiten des
									Auftraggebers, wie z. B. instabiles Internet,
									fehlerhafte Geräte oder unzureichende Akkuladung,
									berechtigen nicht zu einer Wiederholung oder
									Rückerstattung der Unterrichtseinheit.  
								</li>
								<li>
									5.4.3. Der Auftraggeber ist verpflichtet, vor Beginn
									der Einheit sicherzustellen, dass alle erforderlichen
									Programme (z. B. Zoom) installiert und einsatzbereit
									sind.  
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>6. Gewährleistung und Haftung</li>
								<li>
									Für Unterricht und Videoanalysen wird ein umfassender
									Haftungsausschluss mit der Maßgabe vereinbart, dass
									jeder Schadensersatzanspruch des Auftraggebers gegen
									mich für materielle und immaterielle Schäden
									ausgeschlossen wird, auch für etwaige Schäden, die
									auf leichte und auch grobe Fahrlässigkeit
									zurückgeführt werden können.
								</li>
								<li>
									Es gibt keine Garantie und keine Beweise auf
									Richtigkeit des Gesprächs, ebenso wie kein
									Versprechen, dass sich das Verhalten des Tieres
									dadurch verändert. Deswegen übernehme ich keine
									Haftung für vermeintlich entstandene Nachteile oder
									Schäden aus dem Gespräch.
								</li>
								<li>
									6.1. Es gelten die gesetzlichen
									Gewährleistungsbestimmungen, sofern in diesen AGB
									nicht wirksam anderes vereinbart wurde.
								</li>
								<li>
									6.2. Insofern Mängelbehebungen vorgenommen werden,
									stellen derartige Mängelbehebungsarbeiten kein
									Anerkenntnis des Mangels dar.
								</li>
								<li>
									6.3. Die Haftung der Auftragnehmerin für leichte
									Fahrlässigkeit, sofern es sich nicht um
									Personenschäden handelt, wird ausdrücklich
									ausgeschlossen.
								</li>
								<li>
									6.4. Festgehalten wird, dass die Auftragnehmerin für
									nicht durch sie verursachte, allfällige Schäden,
									welche durch nicht absehbare Reaktionen des Pferdes
									entstehen können, nicht haftet.
								</li>
								<li>
									6.5. Der Auftraggeber haftet uneingeschränkt für
									Sach- und Personenschäden, welche durch sein
									Verschulden verursacht wurden. Dies gilt insbesondere
									für Schäden infolge von unsachgemäßem Verhalten in
									der Nähe der Pferde oder die durch nicht absehbare
									Reaktionen des Pferdes entstehen können.
								</li>
								<li>
									6.6. Der Auftraggeber trägt die volle Verantwortung
									für das Tragen von Sicherheitsausrüstung (z. B.
									Reithelm, Sicherheitsweste). Empfehlungen der
									Auftragnehmerin zur Sicherheit sind stets zu
									befolgen.
								</li>
								<li>
									6.7. Die Auftragnehmerin übernimmt keine Haftung für
									Schäden, die durch unsachgemäße Umsetzung der
									Anweisungen während des digitalen Unterrichts
									entstehen. Der Auftraggeber trägt die Verantwortung
									dafür, die Anweisungen entsprechend seiner
									Fähigkeiten und unter Berücksichtigung der Sicherheit
									des Pferdes umzusetzen.
								</li>
								<li>
									6.8. Für technische Ausfälle oder Unterbrechungen,
									die außerhalb der Kontrolle der Auftragnehmerin
									liegen (z. B. Internetprobleme des Auftraggebers),
									übernimmt die Auftragnehmerin keine Haftung.
								</li>
								<li>
									6.9. Der Auftraggeber ist dafür verantwortlich, die
									Sicherheit von Pferd und Reiter während des Trainings
									sicherzustellen und notwendige
									Sicherheitsvorkehrungen (z. B. Anwesenheit einer
									ersten Hilfe-fähigen Person) zu treffen. .
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>7. Datenschutz</li>
								<li>
									Der Auftraggeber wird gemäß Datenschutzgrundgesetz
									ausdrücklich darauf hingewiesen, dass in Erfüllung
									des Vertragsverhältnisses von der Auftragnehmerin
									Daten des Auftraggebers, insbesondere Namen, Adresse,
									E-Mailadresse und Telefonnummer, zum Zwecke der
									automationsunterstützten Abwicklung und Erfassung der
									Beauftragung auf Datenträger gespeichert und gemäß
									den Bestimmungen des Datenschutzgesetzes verarbeitet
									werden. Die Verwendung der Daten des Auftraggebers
									erfolgt ausschließlich zum Zwecke der Erfüllung des
									Vertragsverhältnisses.
								</li>
								<li>
									Der Auftraggeber gibt die Einwilligung zur
									Verarbeitung der personenbezogenen Daten, mitunter
									auch der Gesundheitsdaten. 
								</li>
								<li>
									7.1. Im Rahmen von Videoanalysen oder aufgezeichneten
									Unterrichtseinheiten dürfen Bild- und
									Videomaterialien nur mit ausdrücklicher Zustimmung
									des Auftraggebers genutzt werden.  
								</li>
								<li>
									7.2. Die Auftragnehmerin verpflichtet sich, diese
									Materialien ausschließlich zu Analysezwecken zu
									verwenden und nicht an Dritte weiterzugeben, es sei
									denn, der Auftraggeber stimmt schriftlich zu.  
								</li>
								<li>
									7.3. Aufzeichnungen werden nach Abschluss der Analyse
									innerhalb von 30 Tagen gelöscht, sofern keine
									anderslautende Vereinbarung besteht.  
								</li>
							</ul>
							<br />
							<br />
							<ul className="flex flex-col gap-5">
								<li>8. Allgemeines</li>
								<li>
									8.1. Jegliche Änderungen oder Ergänzungen des AGB
									sowie der abzuschließenden Verträge und
									Vereinbarungen bedürfen zu ihrer Rechtwirksamkeit der
									Schriftform. Dies gilt auch für ein allfälliges
									Abgehen von dieser Schriftformerfordernis.
								</li>
								<li>
									8.2. Sollte eine Bestimmung dieser AGB unwirksam sein
									oder werden, wird die Gültigkeit der restlichen
									Bestimmungen hiervon nicht berührt. Unwirksame
									Bestimmungen sind durch eine der unwirksamen
									Bestimmung am nächsten kommende Bestimmung zu
									ersetzen.
								</li>
								<li>
									8.3. Es gilt deutsches Recht auf die
									Geschäftsbeziehung, sofern dem nicht zwingende
									gesetzliche Regelungen entgegenstehen. Erfüllungsort
									ist der Sitz der Auftragnehmerin in 23909 Bäk.
								</li>
								<li>
									Bei Fragen oder Unklarheiten zu unseren AGB stehen
									wir dir gerne zur Verfügung.
								</li>
								<li>
									Alle Details findest du auch unter
									www.reittraining-buchen.de.
								</li>
							</ul>
						</div>
						<div className="md:col-span-4">
							<h5 className="h5">Haftungsausschluss: </h5>
							<p className="mb-6">
								Die Informationen auf dieser Website werden mit größter
								Sorgfalt erstellt. Ich übernehme jedoch keine Gewähr für
								die Richtigkeit, Vollständigkeit und Aktualität der
								bereitgestellten Inhalte.
							</p>
							<p className="mb-6">
								Links zu externen Websites: <br /> Diese Website enthält
								Links zu externen Websites. Ich habe keinen Einfluss auf
								die Inhalte dieser Seiten und übernehme dafür keine
								Haftung.
							</p>
							<p className="">
								Datenschutz: <br /> Für Informationen zur Erhebung und
								Verarbeitung personenbezogener Daten verweisen wir auf
								unsere Datenschutzerklärung.
							</p>
						</div>
					</div>
				</div>
			</div>
		</Layout>
	);
};

export default Datenschutz;
