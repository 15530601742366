import React from "react";
import { HashLink } from "react-router-hash-link";
import { cn } from "./cn";
import { icons } from "./icons";
const Header = () => {
	return (
		<header
			className={cn(
				"sticky top-0 left-0 w-full py-5 z-[100] shadow-shadow rounded-b-2xl bg-white xl:pt-[40px]"
			)}
		>
			<div className="container xl:max-w-[1144px]">
				<div className="flex justify-between items-center gap-4">
					<HashLink to="/#" className="block">
						<img src="/img/logo.svg" alt="" />
					</HashLink>
					<ul className="hidden lg:flex flex-wrap gap-4 xl:gap-x-10">
						{menu.map((link, index) => (
							<li key={index}>
								<HashLink
									to={link.url}
									className="font-semibold xl:text-[18px] hover:text-cta"
								>
									{link.name}
								</HashLink>
							</li>
						))}
					</ul>
					<div className="flex gap-4 items-center">
						<a
							href="https://wa.me/4945416061459"
							target="_bank"
							className="block"
						>
							{icons.whatsapp}
						</a>
						<HashLink className="btn max-md:py-[10px]" to="/#Booking">
							<span className="hidden md:block">
								JETZT TRAINING BUCHEN
							</span>
							<span className="md:hidden">{icons.calendar}</span>
						</HashLink>
					</div>
				</div>
			</div>
		</header>
	);
};
const menu = [
	{
		name: "Vorteile",
		url: "/#Vorteile",
	},
	{
		name: "Training",
		url: "/#Training",
	},
	{
		name: "Ablauf",
		url: "/#Ablauf",
	},
	{
		name: "Trainer",
		url: "/#Trainer",
	},
	{
		name: "FAQ",
		url: "/#FAQ",
	},
];
export default Header;
