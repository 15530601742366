import React from "react";
import { HashLink } from "react-router-hash-link";
import { cn } from "../../../components/cn";
import { experienceIcon, icons } from "../../../components/icons";

const Experience = () => {
	return (
		<section
			className="experience-section pt-[41px] pb-[60px] overflow-hidden"
			id="Trainer"
		>
			<div className="container xl:max-w-[1280px]">
				<div className="md:text-center md:mx-auto md:max-w-[850px] md:text-lg">
					<div className="text-primary max-sm:text-sm mb-3">
						DEINE TRAINERIN
					</div>
					<h2 className="h2 mb-4 max-sm:text-[26px]">Über mich</h2>
					<p className="mb-5">
						Hallo, ich bin Linn! Pferde begleiten mich schon mein Leben
						lang, und mit viel <br /> Einfühlungsvermögen und Geduld habe
						ich zahlreiche Jungpferde beim Einreiten unterstützt. Mit
						meinem Wallach Gino arbeitete ich erfolgreich bis zur hohen
						Dressurklasse S** und entwickelte dabei ein tiefes Verständnis
						für die Zusammenarbeit zwischen Pferd und Reiter.
					</p>
					<p className="mb-12">
						<strong>
							Seit über 15 Jahren bin ich als mobile Trainerin aktiv
						</strong>{" "}
						und habe ein feines Gespür für die individuellen Bedürfnisse
						von Pferd und Reiter entwickelt. Jetzt freue ich mich darauf,
						auch dich und dein Pferd auf eurem Weg zu unterstützen!
					</p>
				</div>
				<div className="flex flex-col gap-[60px]">
					{data.map((item, index) => (
						<div
							className={cn(
								"flex flex-col md:flex-row gap-10 xl:gap-16 items-center",
								{
									"md:flex-row-reverse": index % 2 == 1,
								}
							)}
							key={index}
						>
							<div
								className="md:w-1/2"
								style={{
									direction: index % 2 == 0 ? "rtl" : "",
								}}
							>
								<img
									src={item.img}
									alt=""
									className={cn(
										"max-w-[unset] rounded-[25px] block mx-auto shadow-shadow3",
										{
											"lg:translate-x-10": index % 2 == 1,
											"lg:-translate-x-10": index % 2 == 0,
											"max-w-full": index == 1,
											"max-md:w-full max-sm:w-[120%]": index == 0,
											"max-md:w-full max-sm:w-[120%]": index == 2,
										}
									)}
								/>
							</div>
							<div className="md:w-1/2">
								<h2 className="h2 gap-4 flex items-center md:text-[30px] md:leading-[38px] mb-4 max-sm:text-2xl">
									<span className="">{item.icon}</span>
									<span className="w-0 flex-grow">{item.title}</span>
								</h2>
								<div className="md:text-lg">
									<div
										className="mb-8"
										dangerouslySetInnerHTML={{
											__html: item.text,
										}}
									></div>
									<ul className="flex flex-col gap-5">
										{item.info.map((infoItem, i) => (
											<li className="flex gap-3" key={i}>
												{icons.check}
												<span className="w-0 flex-grow">
													{infoItem}
												</span>
											</li>
										))}
									</ul>
								</div>
							</div>
						</div>
					))}
				</div>
				<div className="mt-[60px] flex justify-center">
					<HashLink className="btn" to="/#Booking">
						JETZT TRAINING BUCHEN
					</HashLink>
				</div>
			</div>
		</section>
	);
};
const data = [
	{
		icon: experienceIcon.experience1,
		img: "/img/experience1.png",
		title: "Erfahrung & Qualifikation",
		text: "Mit langjähriger Erfahrung und umfassender Fachkenntnis begleite ich Reiter und Pferde auf ihrem Weg zu mehr Harmonie und Erfolg.",
		info: [
			"Über 15 Jahre Erfahrung als mobile Trainerin",
			"Von Jungpferdeausbildung bis zur Dressurklasse S**",
			"Methodik: geduldig, einfühlsam und lösungsorientiert",
		],
	},
	{
		icon: experienceIcon.experience2,
		img: "/img/experience2.png",
		title: "Trainingsansatz",
		text: "<div class='max-w-[477px]'>Mein Ziel ist eine vertrauensvolle Basis zwischen dir und deinem Pferd, um eure Stärken gezielt zu fördern und dich in deiner Selbstständigkeit zu fördern.</div>",
		info: [
			"Individuelle Unterstützung für dich und dein Pferd – angepasst an eure Ziele",
			"Verständliche Tipps und bildhafte Anleitungen für schnelle Erfolge",
			"Abwechslungsreiche Übungen und kleine Herausforderungen für kontinuierliches Wachstum",
		],
	},
	{
		icon: experienceIcon.experience3,
		img: "/img/experience3.png",
		title: "Dein Trainingserlebnis",
		text: "Freu dich auf ein motivierendes Training, das dich und dein Pferd gezielt weiterbringt – flexibel und individuell angepasst.",
		info: [
			"Intensives und persönliches Reitcoaching",
			"individueller Trainingsplan für nachhaltige Fortschritte",
			"inspirierende Ansätze, die dich motivieren",
		],
	},
];

export default Experience;
