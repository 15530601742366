import React from "react";
import { HashLink } from "react-router-hash-link";
import { icons } from "../../../components/icons";

const Banner = () => {
	return (
		<section
			className="-mt-4"
			style={{
				background: "url(img/banner.png) no-repeat center center / cover",
			}}
		>
			<div className="container xl:max-w-[1226px] pt-[66px] pb-[54px]">
				<div className="max-w-[729px] p-10 rounded-[20px] bg-white">
					<h1 className="text-[28px] leading-[32px] tracking-tighter mb-8 md:text-[48px] md:leading-[50px] font-semibold">
						Erlebe dein LIVE-Reittraining per Videocall.
					</h1>
					<ul className="flex flex-col gap-5 mb-[30px] md:text-lg">
						{data.map((info, index) => (
							<li className="flex gap-3" key={index}>
								{icons.check}
								<span className="w-0 self-center flex-grow">
									{info}
								</span>
							</li>
						))}
					</ul>
					<HashLink className="btn max-md:py-[10px]" to="/#Booking">
						JETZT TRAINING BUCHEN
					</HashLink>
				</div>
			</div>
		</section>
	);
};

const data = [
	"30-minütiges, individuelles Training, während du reitest",
	"Persönliche Förderung für dich und dein Pferd – flexibel und fair",
	"Unabhängig vom regionalen Trainerangebot",
];

export default Banner;
