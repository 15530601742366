import { useEffect } from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Datenschutz from "./pages/home/Datenschutz";
import Home from "./pages/home/Home";
import Impressum from "./pages/home/Impressum";

function App() {
	const Wrapper = ({ children }) => {
		const location = useLocation();
		useEffect(() => {
			setTimeout(() => {
				document.documentElement.scrollTo(0, 0);
			}, 0);
		}, [location.pathname, location.search]);
		return children;
	};
	return (
		<BrowserRouter>
			<Wrapper>
				<Routes>
					<Route path="/" element={<Home />} />
					<Route path="/datenschutz" element={<Datenschutz />} />
					<Route path="/impressum" element={<Impressum />} />
				</Routes>
			</Wrapper>
		</BrowserRouter>
	);
}

export default App;
