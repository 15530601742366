import React from "react";
import Layout from "../../components/Layout";
import Banner from "./component/Banner";
import ChooseDate from "./component/ChooseDate";
import Cta from "./component/Cta";
import DigitalRidingAdvantages from "./component/DigitalRidingAdvantages";
import Experience from "./component/Experience";
import Faq from "./component/Faq";
import HowBookingWorks from "./component/HowBookingWorks";
import Pricing from "./component/Pricing";
import Reviews from "./component/Reviews";
import TrainingCourse from "./component/TrainingCourse";

const Home = () => {
	return (
		<Layout>
			<Banner />
			<ChooseDate />
			<DigitalRidingAdvantages />
			<Pricing />
			<div className="bg-sand">
				<HowBookingWorks />
				<TrainingCourse />
			</div>
			<Experience />
			<Reviews />
			<Faq />
			<Cta />
			<div className="border-t border-current max-w-[1090px] mx-auto w-full"></div>
		</Layout>
	);
};

export default Home;
