import React from "react";
import { HashLink } from "react-router-hash-link";
import { icons } from "../../../components/icons";

const Pricing = () => {
	return (
		<section className="pricing-section py-12 md:py-[60px]" id="Training">
			<div className="container">
				<div className="max-w-[1012px] mx-auto">
					<div className="grid grid-cols-1 md:grid-cols-12 max-md:gap-y-12">
						<div className="max-w-[355px] md:col-span-5">
							<h6 className="font-semibold text-cta flex items-center gap-2 mb-2">
								{icons.calendar2} <span>Produktbeschreibung</span>
							</h6>
							<h2 className="h2 mb-5 font-bold">
								Inhalt der Trainingseinheit
							</h2>
							<p className="md:text-lg mb-6">
								Mit meinem digitalen Reittraining erhältst du
								maßgeschneiderte Unterstützung, die dich und dein Pferd
								gezielt auf eure Ziele vorbereitet und euch dabei hilft,
								gemeinsam Fortschritte zu erzielen.
							</p>
							<div className="font-semibold mb-5">
								Zahlungsmöglichkeit <br /> bequem via PayPal.
							</div>
							<img src="/img/paypal.svg" alt="" className="max-w-full" />
						</div>
						<div className="md:col-span-7">
							<div className="relative">
								<div className="absolute bottom-full flex items-start font-lg font-semibold text-cta right-[10%] translate-y-7">
									{arrow}
									<span className="translate-y-[-6px]">
										Los gehts!
									</span>
								</div>
								<div className="max-w-[380px] mx-auto p-[30px] md:p-10 bg-primaryLight rounded-[30px] md:rounded-[40px] shadow-shadow2 border border-[#e9eaeb]">
									<h3 className="text-center text-2xl font-semibold mb-[15px] md:mb-8 md:text-[38px] md:leading-[1] tracking-tighter">
										30min / 45,00€
									</h3>
									<ul className="flex flex-col gap-4">
										{data.map((info, index) => (
											<li key={index} className="flex gap-3">
												{icons.check}
												<span className="w-0 flex-grow">
													{info.title}
												</span>
											</li>
										))}
									</ul>
									<div className="mt-[30px]">
										<HashLink className="btn w-full" to="/#Booking">
											JETZT TRAINING BUCHEN
										</HashLink>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};
const data = [
	{
		title: "30 Minuten Live-Training via Zoom Videocall oder Pivo Meet",
	},
	{
		title: "Jeder Termin wird basierend auf deinem Fortschritt angepasst",
	},
	{
		title: "Direkte Anweisungen und Tipps während des Reitens",
	},
	{
		title: "Profitiere von gezielten Übungen und bildhaften Beschreibungen",
	},
	{
		title: "Nach jeder Einheit erhältst du Anregungen und Trainingsideen",
	},
];
const arrow = (
	<svg
		width="70"
		height="58"
		viewBox="0 0 70 58"
		fill="none"
		xmlns="http://www.w3.org/2000/svg"
	>
		<path
			d="M39.0158 9.90085C26.1315 17.6816 18.9779 31.9118 14.6785 45.8287C14.4575 46.544 14.1873 47.2618 13.9404 47.9604C13.25 46.5675 8.53917 34.9996 6.63728 35.7069C5.52576 36.1203 6.16158 37.6058 6.40619 38.2051C8.17675 42.5371 9.90645 46.4208 12.022 50.61C13.6143 53.7638 16.5773 52.9417 19.5123 51.9818C23.9934 50.5164 24.3571 50.0962 28.9466 48.4096C29.703 48.1314 30.4979 47.9066 31.199 47.5591C32.0233 47.1501 32.9071 46.2844 32.4695 45.3409C31.9735 44.2714 30.8945 44.347 29.9728 44.7396C29.7803 44.822 29.5849 44.8976 29.3877 44.9685C27.2215 45.7462 17.3895 49.8949 17.062 49.1935C16.9515 48.9574 16.9888 48.6826 17.0306 48.4253C18.2342 41.0032 21.4214 33.933 25.1866 27.473C27.7199 23.1259 30.9713 19.1993 34.7601 15.8873C37.8047 13.2256 41.2371 10.9659 44.9762 9.40616C48.9857 7.73336 52.866 7.38869 57.1492 7.13427C59.6151 6.98805 57.662 4.63879 56.2901 4.37417C54.7823 4.0829 53.168 4.60597 51.7031 4.91091C47.0692 5.87799 42.8375 7.59313 39.0158 9.90085Z"
			fill="#01C1A0"
		/>
	</svg>
);

export default Pricing;
