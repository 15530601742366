import React from "react";
import { icons } from "../../../components/icons";

const Cta = () => {
	return (
		<section className="cta-section">
			<div className="container">
				<div className="max-w-[1162px] bg-primary mx-auto md:rounded-[30px] grid grid-cols-12">
					<div className="p-10 md:py-[52px] md:px-[59px] col-span-12 md:col-span-8">
						<h2 className="h2 mb-5 xl:text-[48px] xl:leading-[55px]">
							Noch unsicher?
						</h2>
						<p className="lg:text-lg max-w-[604px] mb-5">
							Lerne mich in einem unverbindlichen Kennenlerngespräch
							kennen. Erzähle mir von deinen Zielen und erfahre, wie ich
							dir und deinem Pferd helfen kann. Melde dich jetzt.{" "}
						</p>
						<a href="https://wa.me/4945416061459" target="_blank">
							{icons.whatsapp}
						</a>
					</div>
					<div className="col-span-12 md:col-span-4">
						<img
							src="/img/cta.png"
							className="w-full h-full object-cover"
							alt=""
						/>
					</div>
				</div>
			</div>
		</section>
	);
};

export default Cta;
