import React, { useEffect, useRef, useState } from "react";
import { faqIcons } from "../../../components/icons";

const Faq = () => {
	const [open, setOpen] = useState([]); // Tracks open FAQ items
	const [maxHeights, setMaxHeights] = useState([]); // Track max-height for each FAQ
	const contentRefs = useRef([]); // Store refs for each FAQ item

	useEffect(() => {
		const newMaxHeights = data.map((_, index) => {
			if (contentRefs.current[index]) {
				return contentRefs.current[index].scrollHeight;
			}
			return 0;
		});
		setMaxHeights(newMaxHeights);
	}, [open]);

	return (
		<section className="faq-section" id="FAQ">
			<div className="container">
				<div className="md:text-center max-w-[672px] mx-auto mb-10 md:mb-[60px]">
					<h2 className="h2 mb-5">Oft gefragt.</h2>
					<p className="text-xl">
						Hier findest du die am häufigsten gestellten Fragen. Wenn du
						noch mehr Fragen haben solltest, kannst du mir eine{" "}
						<a
							className="font-bold"
							href="mailto:info@reittraining-buchen.de"
						>
							E-Mail
						</a>{" "}
						schreiben oder ein kostenloses Kennenlerngespräch mit mir
						vereinbaren.
					</p>
				</div>
				<div className="flex flex-col gap-[10px] max-w-[768px] mx-auto">
					{data?.map((faq, fIndex) => (
						<div
							className="border-b border-[#E9EAEB] pb-[26px] last:border-b-0 last:pb-0"
							key={fIndex}
						>
							<h5
								className="flex justify-between items-center text-lg font-semibold cursor-pointer pb-2 pt-[22px] select-none"
								onClick={() => {
									if (open.includes(fIndex)) {
										setOpen(open.filter((i) => i !== fIndex));
									} else {
										// setOpen([...open, fIndex]);
										setOpen([fIndex]); // single item open
									}
								}}
							>
								<span className="w-0 flex-grow">{faq.title}</span>
								<span>
									{open.includes(fIndex)
										? faqIcons.minus
										: faqIcons.plus}
								</span>
							</h5>
							<div
								className={`transition-all duration-500 ease-in-out overflow-hidden`}
								style={{
									maxHeight: open.includes(fIndex)
										? `${maxHeights[fIndex]}px`
										: "0px",
								}}
							>
								<div
									ref={(el) => (contentRefs.current[fIndex] = el)}
									dangerouslySetInnerHTML={{
										__html: faq.text,
									}}
								></div>
							</div>
						</div>
					))}
				</div>
			</div>
		</section>
	);
};
const data = [
	{
		title: "Ist Online-Reitunterricht für mich geeignet?",
		text: "Wenn du bereits frei reiten kannst, ist der Unterricht für dich geeignet. Wenn du noch Minderjährig sein solltest, wird die Anwesenheit mindestens einer erwachsenen Person vorausgesetzt.",
	},
	{
		title: "Was ist eigentlich ein online Reittraining LIVE?",
		text: "Mit der Live-Videoübertragung begleite ich dich und dein Pferd während des Reitens in Echtzeit und kann euch auf direktem Wege meine Unterstützung anbieten. Während ich vor dem Bildschirm sitze und in den Gedanken voll und ganz bei dir bin, sitzt du mit deinem Bluetooth Kopfhörern im Ohr auf deinem Pferd in deiner gewohnten Umgebung. Dein Handy filmt dich von einem geeigneten Standort auf dem Reitplatz aus, sodass ich dich ideal sehen kann und du meine Anweisungen und Tipps ohne Probleme hören kannst. So kann ich dich beim Training effektiv begleiten.",
	},
	{
		title: "Wie genau läuft so ein online Reittraining ab?",
		text: `
		<div>
			Zusammenfassung <br />
			Buchung 
			<br />
			<ul class="list-disc pl-6 mb-3">
				<li>Wunschtermin online buchen</li>
				<li>E-Mail mit allen Informationen und deinem Zoom-Link erhalten</li>
			</ul>
			Vorbereitung
			<br />
			<ul class="list-disc pl-6 mb-3">
				<li>Technische Vorbereitung </li>
				<li>Vorbereitung von dir und deinem Pferd </li>
			</ul>
			Ablauf des Coachings
			<br />
			<ul class="list-disc pl-6 mb-3">
				<li>Einloggen in den Wartebereich</li>
				<li>Coaching-Start</li>
				<li>Reittraining genießen </li>
				<li>Feedback und Ausblick</li>
			</ul>
			<br />
			Ablauf des Online-Reitcoachings (LIVE)
			<br />
			1. Buchung und Vorbereitung
			<br />
			Der erste Schritt ist die Online-Buchung deines gewünschten Coachings. Einfach, bequem und direkt von zu Hause aus. Wähle deinen Wunschtermin und buche online. Nach der Buchung erhältst du eine Bestätigungs-E-Mail mit allen wichtigen Infos und dem Zoom-Link für den Videocall. Dort findest du auch eine Belehrung zu den Voraussetzungen und Empfehlungen für das Coaching. Logge dich rechtzeitig ein und bereite alles vor. Stelle sicher, dass du alle technischen Geräte und das Setup bereit hast, damit das Coaching reibungslos verläuft.
			<br />
			<br />
			2. Ablauf des Coachings
			<br />
			Logge dich rechtzeitig in den Wartebereich des Videocalls ein. Dies erfolgt vor dem Warmreiten, damit du vorbereitet bist und wir gleich starten können. Das Coaching beginnt mit einem kurzen Gespräch über deine Wünsche und Visionen für dein Training sowie die Herausforderungen, die euch derzeit im Weg stehen. Wir sprechen über die spezifischen Ziele, die du im Training erreichen möchtest. <br />
			Während du reitest, gehe ich gezielt auf deine Wünsche ein, gebe dir Impulse und Feedback zu deinem Reiten und helfe dir, deine Technik zu verbessern. Ich gebe dir direkte Anweisungen, die du während des Trainings umsetzen kannst, um dein Reiten weiterzuentwickeln. Zum Schluss erhältst du eine Zusammenfassung deines Trainings, um auch in den kommenden Tagen selbstständig weiter an eurem Ziel arbeiten zu können. <br />
			Nach dem Coaching endet der Videocall, und du kannst mit deinem Pferd noch ein wenig trocken reiten, um das Training ausklingen zu lassen. Ich freue mich, wenn du mir ein Feedback gibst und ich freue mich auf ein baldiges Wiedersehen im nächsten Coaching!
		</div>
		`,
	},

	{
		title: "Wie kann ich mein online-Reittraining richtig vorbereiten?",
		text: `
		<div>
			Deine Vorbereitung für das Online-Coaching
			<br />
			<ul class="list-disc mb-4 pl-6">
				<li>Buchung: Hast du deinen Termin online gebucht?</li>
				<li>E-Mail erhalten: Hast du die Bestätigungs-E-Mail mit allen Infos und dem Zoom-Link erhalten?</li>
				<li>
					Technische Geräte bereit:
					<ul class="list-disc my-3 pl-6">
						<li>
							Hast du ein internetfähiges Gerät mit Kamera (Smartphone, Tablet, Laptop)?
						</li>
						<li>
							Ist der Akkustand aller Geräte ausreichend?
						</li>
						<li>
							Ist die entsprechende  App (Zoom, Pivo Meet) installiert und aktuell?
						</li>
						<li>
							Sind die Bluetooth-Kopfhörer bereit (mind. 30 m Reichweite)?
						</li>
						<li>
							Hast du einen stabilen Kamerastandort (Stativ oder Kameramann/-frau)?
						</li>
						<li>
							Schnelles Internet für eine stabile Videoübertragung
						</li>
					</ul>
				</li>
				<li>
					Reiter und Pferd 
					<ul class="list-disc my-3 pl-6">
						<li>
							Ist dein Pferd schon warm, aber noch nicht müde geritten?
						</li>
						<li>
							Trägst du kontrastreiche Farben, damit du auf dem Video gut sichtbar bist und der Trainer alle Bewegungen gut erkennen kann?
						</li>
					</ul>
				</li>
				<li>
					Einloggen: Hast du dich rechtzeitig im Wartebereich des Zoom-Videocalls eingeloggt?
				</li>
			</ul>
			Nach der Buchung bekommst du alle wichtigen Infos, und nach einem kurzen Gespräch zu Beginn des Coachings geht es direkt los. Während des Reitens gebe ich dir wertvolle Impulse und Feedback, die du sofort umsetzen kannst. Nach dem Coaching bekommst du eine Zusammenfassung per E-Mail, und ich freue mich auf dein Feedback.
		</div>
		`,
	},

	{
		title: "Was brauche ich für den Reitunterricht per Videocall und warum?  ",
		text: `
		<div>
			Zusammenfassung der notwendigen technischen Mittel:
			<br />
			<div class="my-2">Zusammenfassung:</div>
			<ol class="list-decimal mb-4 pl-6">
				<li>Internetfähiges Kamera-Gerät (Smartphone oder Tablet) mit mindestens 1080p Auflösung.</li>
				<li>Stativ oder jemanden, der dich filmt oder bewegliches Kamerasystem wie das Pivo Equestrian Silver Stativ für stabile Aufnahmen.</li>
				<li>Bluetooth-Kopfhörer mit guter Reichweite, Noise-Cancelling und stabilem Sitz.</li>
				<li>Live-Training-App wie Pivo Meet, Zoom oder Skype für Echtzeit-Kommunikation.</li>
				<li>Stabile Internetverbindung (WLAN oder mobile Daten) für ununterbrochenes Streaming.</li>
				<li>Powerbank oder externer Akku, um Geräte während des Trainings mit Strom zu versorgen.</li>
			</ol>
		</div>
		`,
	},

	{
		title: "Wie funktioniert das Training mit den Pivo Equestrian Silver Stativ und Pivo Meet?",
		text: `
		<div>
			Ein Online-Reittraining mit der Kombination aus dem Pivo Equestrian Silver Stativ, der Pivo Plus App und der Funktion Pivo Meet bietet dir die Möglichkeit, dein Training in Echtzeit mit einem Trainer zu teilen, ohne dass der Trainer physisch anwesend sein muss. Der Trainer kann dein Reiten live verfolgen und dir sofortiges Feedback geben, was dir hilft, dein Reiten in Echtzeit zu verbessern.
			<br />
			Zusammenfassung:
			<ol class="list-decimal my-4 pl-6">
				<li>Pivo Equestrian Silver Stativ aufstellen und Smartphone montieren.</li>
				<li>Pivo Plus App und Pivo Meet für das Live-Training einrichten.</li>
				<li>Link für Live-Training an den Trainer senden und Verbindung herstellen.</li>
				<li>Live-Training starten: Trainer gibt direktes Feedback während der 30-minütigen Einheit.</li>
			</ol>
			<div>
				Warum Class 1 Bluetooth für größere Reichweite wichtig ist: <br />
				Class 1 Bluetooth ist der Standard, der für größere Reichweiten sorgt. Während die meisten Bluetooth-Geräte mit einer Reichweite von 10-30 m auskommen, bieten Geräte mit Class 1 Bluetooth eine Reichweite von bis zu 100 m (unter optimalen Bedingungen). Dies ist besonders vorteilhaft für Aktivitäten, bei denen du dich weit von der Quelle (z.B. dem Smartphone oder Trainer) entfernen möchtest.
			</div>
		</div>
		`,
	},

	{
		title: "Welche Bluetooth Kopfhörer benötige ich für das online Reittraining?",
		text: `
		<div>
			Hierbei handelt es sich nur um eine Empfehlung. Ich garantiere nicht, dass die hier vorgestellten Kopfhörer die von euch gewünschte Qualität liefern. Ich bitte euch daher auch selbst zu recherchieren. <br />
			Hier sind einige Bluetooth-Kopfhörer, die gut zu deinen Anforderungen passen könnten:
			<ol class="list-decimal my-4 pl-6">
				<li>Jabra Elite 7 Pro</li>
				<li>Bose QuietComfort Earbuds II</li>
				<li>Sony WF-1000XM5</li>
				<li>Shure AONIC 215</li>
				<li>Plantronics BackBeat PRO 5100</li>
			</ol>
			<div>
				Warum Class 1 Bluetooth für größere Reichweite wichtig ist: <br />
				Class 1 Bluetooth ist der Standard, der für größere Reichweiten sorgt. Während die meisten Bluetooth-Geräte mit einer Reichweite von 10-30 m auskommen, bieten Geräte mit Class 1 Bluetooth eine Reichweite von bis zu 100 m (unter optimalen Bedingungen). Dies ist besonders vorteilhaft für Aktivitäten, bei denen du dich weit von der Quelle (z.B. dem Smartphone oder Trainer) entfernen möchtest.
			</div>
		</div>
		`,
	},

	{
		title: "Was passiert, wenn die Verbindung abbricht?",
		text: "Sollte die Internetverbindung abbrechen, kannst du mir ein Video deines Reitens schicken. Innerhalb einer Woche erhältst du eine Sprachnachricht mit einer Kurzanalyse.",
	},
	{
		title: "Wie kann ich den Unterricht absagen?  ",
		text: "In deiner Buchungsbestätigung kannst du den Termin stornieren oder verschieben. Du kannst mich auch per E-Mail kontaktieren. Stornierungen sind bis 24 Stunden vor dem Termin kostenlos möglich.",
	},
];

export default Faq;
